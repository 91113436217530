// All

export const allRU = {
    make_order: 'Оформить заказ',
    addressesWithOptions: [
        {value: '1.2', label: '№1 БГУ, ул. Свердлова, 34. 2-х комнатная'},
        {value: '1.3', label: '№1 БГУ, ул. Свердлова, 34. 3-х комнатная'},
        {value: '2', label: '№2 БГУ, ул. Октябрьская, 2'},
        {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
        {value: '4.1', label: '№9 БГЭУ, ул. Чюрлениса, 1'},
        {value: '4.2', label: '№8 БГПУ, ул. Чюрлениса, 3'},
        {value: '4.3', label: '№1 МГЛУ, ул.Чюрлениса, 5'},
        {value: '4', label: '№4 БГУ, ул. Чюрлениса, 7'},
        {value: '5', label: '№5 БГУ, ул. Октябрьская, 4'},
        {value: '6.1', label: '№6 БГУ, ул. Октябрьская, 10. c холодильником'},
        {value: '6.2', label: '№6 БГУ, ул. Октябрьская, 10. без холодильника'},
        {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        {value: '10', label: '№10 БГУ, ул. Курчатова,8'},
        {value: '11', label: '№11 БГУ, ул. пр-т Дзержинского, 87'},
        {value: '12', label: '№18 БНТУ, пр-т Дзержинского, 83/16'},
        {value: '13', label: '№10 БГМУ, пр-т Дзержинского, 93'},
        {value: '14', label: '№4 БГУИР, пр-т Дзержинского, 95'},
        {value: '15', label: '№4 МГЛУ, пр-т Дзержинского, 97'},
        {value: '16', label: '№ 5 БГПУ, пер.Вузовский, 9/1'},
    ],
    addresses: [
        {value: '1', label: '№1 БГУ, ул. Свердлова, 34'},
        {value: '2', label: '№2 БГУ, ул. Октябрьская, 2'},
        {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
        {value: '4.1', label: '№9 БГЭУ, ул. Чюрлениса, 1'},
        {value: '4.2', label: '№8 БГПУ, ул. Чюрлениса, 3'},
        {value: '4.3', label: '№1 МГЛУ, ул.Чюрлениса, 5'},
        {value: '4', label: '№4 БГУ, ул. Чюрлениса, 7'},
        {value: '5', label: '№5 БГУ, ул. Октябрьская, 4'},
        {value: '6', label: '№6 БГУ, ул. Октябрьская, 10'},
        {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        {value: '10', label: '№10 БГУ, ул. Курчатова, 8'},
        {value: '11', label: '№11 БГУ, ул. пр-т Дзержинского, 87'},
        {value: '12', label: '№18 БНТУ, пр-т Дзержинского, 83/16'},
        {value: '13', label: '№10 БГМУ, пр-т Дзержинского, 93'},
        {value: '14', label: '№4 БГУИР, пр-т Дзержинского, 95'},
        {value: '15', label: '№4 МГЛУ, пр-т Дзержинского, 97'},
        {value: '16', label: '№ 5 БГПУ, пер.Вузовский, 9/1'},
    ],
    currency: 'руб.',
    back: 'Назад',
};

export const allCN = {
    make_order: '下订单',
    addressesWithOptions: [
        {value: '1.2', label: '白大1宿，地址, ул. Свердлова, 34. 两房式'},
        {value: '1.3', label: '白大1宿，地址, ул. Свердлова, 34. 三房'},
        {value: '2', label: '白大2宿，地址, ул. Октябрьская, 2'},
        {value: '3', label: '白大3宿, 地址, ул. Ак. Курчатова, 6'},
        {value: '4.1', label: '9号BSEU, ул. Чюрлениса, 1'},
        {value: '4.2', label: '第 8 名 MSLU, ул. Чюрлениса, 3'},
        {value: '4.3', label: '白大1宿，地址, ул. Чюрлениса, 5'},
        {value: '4', label: '白大4宿，地址, ул. Чюрлениса, 7'},
        {value: '5', label: '白大5宿，地址, ул. Октябрьская, 4'},
        {value: '6.1', label: '白大6宿，地址, ул. Октябрьская, 10. 带冰箱'},
        {value: '6.2', label: '白大6宿，地址, ул. Октябрьская, 10. 没有冰箱'},
        {value: '7', label: '白大7宿，地址, ул. Октябрьская, 10/a'},
        {value: '10', label: '白大10宿，地址, ул. Курчатова,8'},
        {value: '11', label: '白大11宿，地址, ул. пр-т Дзержинского, 87'},
        {value: '12', label: '18号班图, пр-т Дзержинского, 83/16'},
        {value: '13', label: '10号BSMU, пр-т Дзержинского, 93'},
        {value: '14', label: '4号BSUIR, пр-т Дзержинского, 95'},
        {value: '15', label: '4号MSLU, пр-т Дзержинского, 97'},
        {value: '16', label: '5号BSPU, пер.Вузовский, 9/1'},
    ],
    addresses: [
        {value: '1', label: '白大1宿，地址, ул. Свердлова, 34'},
        {value: '2', label: '白大2宿，地址, ул. Октябрьская, 2'},
        {value: '3', label: '白大3宿，地址, ул. Ак. Курчатова, 6'},
        {value: '4.1', label: '9号BSEU, ул. Чюрлениса, 1'},
        {value: '4.2', label: '第 8 名 MSLU, ул. Чюрлениса, 3'},
        {value: '4.3', label: '白大1宿，地址, ул. Чюрлениса, 5'},
        {value: '4', label: '白大4宿，地址, ул. Чюрлениса, 7'},
        {value: '5', label: '白大5宿，地址, ул. Октябрьская, 4'},
        {value: '6', label: '白大6宿，地址, ул. Октябрьская, 10'},
        {value: '7', label: '白大7宿，地址, ул. Октябрьская, 10/a'},
        {value: '10', label: '白大10宿，地址, ул. Курчатова,8'},
        {value: '11', label: '白大11宿，地址, ул. пр-т Дзержинского, 87'},
        {value: '12', label: '18号班图, пр-т Дзержинского, 83/16'},
        {value: '13', label: '10号BSMU, пр-т Дзержинского, 93'},
        {value: '14', label: '4号BSUIR, пр-т Дзержинского, 95'},
        {value: '15', label: '4号MSLU, пр-т Дзержинского, 97'},
        {value: '16', label: '5号BSPU, пер.Вузовский, 9/1'},
    ],
    currency: '卢布.',
    back: '后退',
};


// navigations links
export const navigationsLinksRU = {
    'maintenance-cleaning': 'Поддерживающая уборка по подписке',
    'one-time-cleaning': 'Разовая уборка',
    'cleaning-for-eviction': 'Уборка для выселения',
    'wallpaper': 'Поклейка обоев',
    'mold-removal': 'Устранение плесени',
    'wall-repair': 'Ремонт стен',
};
export const navigationsLinksCN = {
    'maintenance-cleaning': '清洁维护',
    'one-time-cleaning': '一次性清洁',
    'cleaning-for-eviction': '退宿打扫清洁',
    'wallpaper': '贴墙纸',
    'mold-removal': '除霉菌',
    'wall-repair': '墙壁修补',
};

// firstSection
export const firstSectionContentRU = {
    placeholder: 'Выберите общежитие',
    price: 'Цена',
    'cleaning-for-eviction': {
        title: 'Уборка для выселения <br/> из общежития',
        subTitle: 'Сервис по уборке комнат, поклейки обоев <br/> и ремонта стен в общежитии',
        options: allRU.addressesWithOptions,
        prices: {
            '1.2': '405 руб.',
            '1.3': '440 руб.',
            '2': '290 руб.',
            '3': '290 руб.',
            '4': '405 руб.',
            '4.1': '405 руб.',
            '4.2': '405 руб.',
            '4.3': '405 руб.',
            '5': '120 руб.',
            '6.1': '120 руб.',
            '6.2': '100 руб.',
            '7': '290 руб.',
            '10': '405 руб.',
            '11': '405 руб.',
            '12': '405 руб.',
            '13': '405 руб.',
            '14': '405 руб.',
            '15': '405 руб.',
            '16': '290 руб.',
        }
    },
    'one-time-cleaning': {
        title: 'Разовая уборка',
        subTitle: 'Подойдет для выселения одного человека <br/> в середине года',
        options: allRU.addressesWithOptions,
        prices: {
            '1.2': '130 руб.',
            '1.3': '140 руб.',
            '2': '105 руб.',
            '3': '105 руб.',
            '4': '140 руб.',
            '4.1': '140 руб.',
            '4.2': '140 руб.',
            '4.3': '140 руб.',
            '5': '40 руб.',
            '6.1': '40 руб.',
            '6.2': '40 руб.',
            '7': '105 руб.',
            '10': '140 руб.',
            '11': '140 руб.',
            '12': '140 руб.',
            '13': '140 руб.',
            '14': '140 руб.',
            '15': '140 руб.',
            '16': '105 руб.',
        }
    },
    'maintenance-cleaning': {
        title: 'Поддерживающая уборка <br/> по подписке',
        subTitle: 'Больше не нужно дежурить',
        wrapperClass: 'maintenance-cleaning',
        options: allRU.addresses,
        prices: {
            '1': '240 руб.',
            '2': '180 руб.',
            '3': '180 руб.',
            '4': '240 руб.',
            '4.1': '240 руб.',
            '4.2': '240 руб.',
            '4.3': '240 руб.',
            // '5': '40 руб.',
            // '6': '40 руб.',
            '7': '180 руб.',
            '10': '240 руб.',
            '11': '240 руб.',
            '12': '240 руб.',
            '13': '240 руб.',
            '14': '240 руб.',
            '15': '240 руб.',
            '16': '180 руб.',
        }
    },



    'wallpaper': {
        title: 'Поклейка обоев <br/> в общежитии',
        subTitle: 'Сделано бывшими студентами <br/> для студентов',
        wrapperClass: 'wallpaper',
        options: allRU.addresses,
        prices: {
            '1': 'от 100 руб.',
            '2': 'от 100 руб.',
            '3': 'от 100 руб.',
            '4': 'от 100 руб.',
            '4.1': 'от 100 руб.',
            '4.2': 'от 100 руб.',
            '4.3': 'от 100 руб.',
            '5': 'от 90 руб.',
            '6': 'от 90 руб.',
            '7': 'от 100 руб.',
            '10': 'от 90 руб.',
            '11': 'от 90 руб.',
            '12': 'от 100 руб.',
            '13': 'от 100 руб.',
            '14': 'от 100 руб.',
            '15': 'от 100 руб.',
            '16': 'от 100 руб.',
        }
    },
    'mold-removal': {
        title: 'Устранение плесени <br/> в общежитиях',
        subTitle: 'Сделано бывшими студентами <br/> для студентов',
        wrapperClass: 'mold-removal',
        options: allRU.addresses,
        prices: {
            '1': 'от 100 руб.',
            '2': 'от 100 руб.',
            '3': 'от 100 руб.',
            '4': 'от 100 руб.',
            '4.1': 'от 100 руб.',
            '4.2': 'от 100 руб.',
            '4.3': 'от 100 руб.',
            '5': 'от 100 руб.',
            '6': 'от 100 руб.',
            '7': 'от 100 руб.',
            '10': 'от 100 руб.',
            '11': 'от 100 руб.',
            '12': 'от 100 руб.',
            '13': 'от 100 руб.',
            '14': 'от 100 руб.',
            '15': 'от 100 руб.',
            '16': 'от 100 руб.',
        }
    },
    'wall-repair': {
        title: 'Ремонт стен <br/> в общежитиях',
        subTitle: 'Сделано бывшими студентами <br/> для студентов',
        wrapperClass: 'wall-repair',
        options: [
            {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
            {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        ],
        prices: {
            '3': '170 руб.',
            '7': '170 руб.',
        }
    },
};
export const firstSectionContentCN = {
    placeholder: '选择宿舍',
    price: '价格',
    'cleaning-for-eviction': {
        title: '退宿打扫清洁',
        subTitle: '打扫宿舍房间，贴壁纸，修补墙壁服务',
        options: allCN.addressesWithOptions,
        prices: {
            '1.2': '405 卢布.',
            '1.3': '440 卢布.',
            '2': '290 卢布.',
            '3': '290 卢布.',
            '4': '405 卢布.',
            '4.1': '405 卢布.',
            '4.2': '405 卢布.',
            '4.3': '405 卢布.',
            '5': '120 卢布.',
            '6.1': '120 卢布.',
            '6.2': '100 卢布.',
            '7': '290 卢布.',
            '10': '405 卢布.',
            '11': '405 卢布.',
            '12': '405 卢布.',
            '13': '405 卢布.',
            '14': '405 卢布.',
            '15': '405 卢布.',
            '16': '290 卢布.',

        }
    },
    'one-time-cleaning': {
        title: '一次性清洁',
        subTitle: '适合年中驱逐一人',
        options: allCN.addressesWithOptions,
        prices: {
            '1.2': '130 卢布.',
            '1.3': '140 卢布.',
            '2': '105 卢布.',
            '3': '105 卢布.',
            '4': '140 卢布.',
            '4.1': '140 卢布.',
            '4.2': '140 卢布.',
            '4.3': '140 卢布.',
            '5': '40 卢布.',
            '6.1': '40 卢布.',
            '6.2': '40 卢布.',
            '7': '105 卢布.',
            '10': '140 卢布.',
            '11': '140 卢布.',
            '12': '140 卢布.',
            '13': '140 卢布.',
            '14': '140 卢布.',
            '15': '140 卢布.',
            '16': '105 卢布.',
        }
    },
    'maintenance-cleaning': {
        title: '维护清洁',
        subTitle: '不再需要保洁',
        wrapperClass: 'maintenance-cleaning',
        options: allCN.addresses,
        prices: {
            '1': '240 卢布.',
            '2': '180 卢布.',
            '3': '180 卢布.',
            '4': '240 卢布.',
            '4.1': '240 卢布.',
            '4.2': '240 卢布.',
            '4.3': '240 卢布.',
            // '5': '40 卢布.',
            // '6': '40 卢布.',
            '7': '180 卢布.',
            '10': '240 卢布.',
            '11': '240 卢布.',
            '12': '240 卢布.',
            '13': '240 卢布.',
            '14': '240 卢布.',
            '15': '240 卢布.',
            '16': '180 卢布.',
        }
    },
    'wallpaper': {
        title: '宿舍贴墙纸',
        subTitle: '由往届学生为学生制作',
        wrapperClass: 'wallpaper',
        options: allCN.addresses,
        prices: {
            '1': '从 100 卢布',
            '2': '从 100 卢布',
            '3': '从 100 卢布',
            '4': '从 100 卢布',
            '4.1': '从 100 卢布',
            '4.2': '从 100 卢布',
            '4.3': '从 100 卢布',
            '5': '从 90 卢布',
            '6': '从 90 卢布',
            '7': '从 100 卢布',
            '10': '从 90 卢布',
            '11': '从 90 卢布',
            '12': '从 100 卢布',
            '13': '从 100 卢布',
            '14': '从 100 卢布',
            '15': '从 100 卢布',
            '16': '从 100 卢布',
        }
    },
    'mold-removal': {
        title: '宿舍防霉',
        subTitle: '由往届学生为学生制作',
        wrapperClass: 'mold-removal',
        options: allCN.addresses,
        prices: {
            '1': '从 100 卢布',
            '2': '从 100 卢布',
            '3': '从 100 卢布',
            '4': '从 100 卢布',
            '4.1': '从 100 卢布',
            '4.2': '从 100 卢布',
            '4.3': '从 100 卢布',
            '5': '从 100 卢布',
            '6': '从 100 卢布',
            '7': '从 100 卢布',
            '10': '从 100 卢布',
            '11': '从 100 卢布',
            '12': '从 100 卢布',
            '13': '从 100 卢布',
            '14': '从 100 卢布',
            '15': '从 100 卢布',
            '16': '从 100 卢布',
        }
    },
    'wall-repair': {
        title: '修补宿舍墙壁',
        subTitle: '由往届学生为学生制作',
        wrapperClass: 'wall-repair',
        options: [
            {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
            {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        ],
        prices: {
            '3': '170 卢布',
            '7': '170 卢布',
        }
    },
};

// guideSection
export const guideSectionContentRU = {
    'cleaning-for-eviction': {
        title: 'Хочу заказать, что делать?',
        subTitle: '',
        cards: [
            {
                icon: 'papers',
                title: 'Оформите договор на уборку',
                text: 'Вы заказываете услугу любым удобным для вас способом, онлайн/лично у менеджера CleanHub',
            },
            {
                icon: 'connection',
                title: 'Передайте договор администрации',
                text: 'Первый, кто выселяется, передает копию договора администрации',
            },
            {
                icon: 'teamwork',
                title: 'Сообщите нам, когда все выселяться',
                text: 'После того как все уедут из комнаты, мы проводим уборку',
            },
        ],
    },
    'maintenance-cleaning': {
        title: 'Почему стоит заказывать уборку по подписке',
        subTitle: '',
        cards: [
            {
                icon: 'bucket',
                title: 'Подписка выгоднее',
                text: 'Цена одной уборки значительно меньше, чем цена разовой',
            },
            {
                icon: 'price-tag',
                title: 'Можно выбрать тариф',
                text: '2 или 4 уборки в месяц',
            },
            {
                icon: 'done',
                title: 'Теперь не нужно составлять графики для дежурства',
                text: 'Уборка с нами будет регулярной ',
            },
        ],
    },
    'wallpaper': {
        title: 'Хочу заказать, что делать?',
        subTitle: '',
        cards: [
            {
                icon: 'papers',
                title: 'Оформите договор на поклейку',
                text: 'Вы заказываете уборку любым удобным для вас способом, онлайн/лично у менеджера CleanHub',
            },
            {
                icon: 'connection',
                title: 'Передайте договор администрации',
                text: 'Первый, кто выселяется, передает копию договора администрации',
            },
            {
                icon: 'teamwork',
                title: 'Сообщите нам, когда все выселяться',
                text: 'После того как все уедут из комнаты, мы проводим поклейку',
            },
        ],
    },
    'mold-removal': {
        title: 'Как происходит устранение плесени?',
        subTitle: '',
        cards: [
            {
                icon: 'bucket',
                title: 'Обработка пораженных частков',
                text: 'Мы наносим специальное средство, чтобы удалить образования плесени',
            },
            {
                icon: 'showel',
                title: 'Зачистка',
                text: 'Мы проводим зачистку пораженных областей',
            },
            {
                icon: 'brush',
                title: 'Покраска',
                text: 'После зачистки мы проводим покраску обработанных мест',
            },
        ],
    },
    'wall-repair': {
        title: 'Что такое ремонт стен?',
        subTitle: ' ',
        cards: [
            {
                icon: 'bucket',
                title: 'Штукатурка',
                text: 'Устранение значительных неровностей и дефектов на поврежденных поверхностях',
            },
            {
                icon: 'showel',
                title: 'Шпаклевка',
                text: 'Устранение мелких дефекто и шероховатостей перед окрашиванием или поклейкой обоев',
            },
            {
                icon: 'brush',
                title: 'Покраска ',
                text: 'Финальных этап ремонта стены с покраской восстановленных поверхностей',
            },
        ],
    },
};
export const guideSectionContentCN = {
    'cleaning-for-eviction': {
        title: '我想下单，该怎么做呢',
        subTitle: '订购服务只需要几个简单的步骤',
        cards: [
            {
                icon: 'papers',
                title: '签订清洁合同',
                text: '您可以通过线上或者线下订购方式找 CleanHub 经理下单',
            },
            {
                icon: 'connection',
                title: '将合同提交给管理员',
                text: '第一，退宿者复印一份合同给管理员',
            },
            {
                icon: 'teamwork',
                title: '退宿时请提前通知我们',
                text: '所有人退宿后，我们将打扫房间',
            },
        ],
    },
    'maintenance-cleaning': {
        title: '为什么您应该通过订阅订购清洁服务',
        subTitle: '',
        cards: [
            {
                icon: 'bucket',
                title: '订阅更有利可图',
                text: '一次清洁的价格明显低于一次性清洁的价格',
            },
            {
                icon: 'price-tag',
                title: '您可以选择资费',
                text: '每月 2 或 4 次清洁',
            },
            {
                icon: 'done',
                title: '现在您无需创建值班计划',
                text: '我们将定期进行清洁 ',
            },
        ],
    },
    'wallpaper': {
        title: '我想下单，该怎么做呢？',
        subTitle: '订购服务只需要几个简单的步骤',
        cards: [
            {
                icon: 'papers',
                title: '签订纸质合同',
                text: '您可以通过线上或者线下找 CleanHub 经理订购服务',
            },
            {
                icon: 'connection',
                title: '将合同复印件提交给管理员',
                text: '第一，退宿者复印一份合同给管理员',
            },
            {
                icon: 'teamwork',
                title: '退宿时请提前通知我们',
                text: '所有人搬走后，我们将打扫房间',
            },
        ],
    },
    'mold-removal': {
        title: '流程是什么样呢？',
        subTitle: '',
        cards: [
            {
                icon: 'bucket',
                title: '处理发霉区域',
                text: '使用清洁工具清除霉菌',
            },
            {
                icon: 'showel',
                title: '清洗擦拭',
                text: '清洁发霉区域',
            },
            {
                icon: 'brush',
                title: '喷漆',
                text: '清洗后，我们将对处理过的区域进行喷漆',
            },
        ],
    },
    'wall-repair': {
        title: '什么是墙面修补？',
        subTitle: '',
        cards: [
            {
                icon: 'bucket',
                title: '抹灰泥',
                text: '清除明显不规则的受损表面',
            },
            {
                icon: 'showel',
                title: '腻子油',
                text: '在粉刷和贴墙纸前打磨墙面粗糙',
            },
            {
                icon: 'brush',
                title: '涂漆',
                text: '墙面修复后，进行粉刷',
            },
        ],
    },
};

// whatWeClean
export const whatWeCleanContentRU = {
    'cleaning-for-eviction': {
        title: 'Что мы <span class="blue">убираем</span>',
        tabs: [
            {
                text: 'КОМНАТА',
                id: 'room',
            },
            {
                text: 'КУХНЯ',
                id: 'kitchen',
            },
            {
                text: 'КОРИДОР',
                id: 'corridor',
            },
            {
                text: 'ТУАЛЕТ',
                id: 'toilet',
            },
            {
                text: 'ВАННАЯ',
                id: 'bathroom',
            },
        ],
        tabsContent: {
            'room': {
                title: 'В комнате',
                listItems: [
                    'моем окна с 2-ух сторон',
                    'убираем все поверхности (подоконник, полки, тумбочки, стол, кровать, каркас стульев)',
                    'обеспыливаем полки в шкафу',
                    'моем дверь ручки, выключател и розетки',
                    'моем пол и плинтус по периметру комнаты',
                ]
            },
            'kitchen': {
                title: 'В кухне',
                listItems: [
                    'убираем в кухонных шкафчиках и полках',
                    'размораживаем и моем холодильник',
                    'моем кухонный фартук и плиту',
                    'моем столешницу и другие поверхности (стол, подоконник, каркасы стульев)',
                    'моем окна с двух с 2-ух сторон',
                    'моем мусорное ведро и вентиляционную решетку',
                    'моем пол (+ устраняем сильные загрязнения)',
                    'моем дверь, ручки, выключатели и розетки',
                ]
            },
            'corridor': {
                title: 'В коридоре',
                listItems: [
                    'убираем пыль со всех поверхностей',
                    'моем двери и зеркало',
                    'моем пол',
                    'холодильник при наличии',
                ]
            },
            'toilet': {
                title: 'В туалете',
                listItems: [
                    'выносим мусор',
                    'чистим раковину и унитаз ',
                    'чистим смеситель',
                    'обеспыливаем все поверхности',
                    'моем зеркало и тумбочку под раковиной',
                    'моем вентиляционную решетку',
                    'моем пол',
                ]
            },
            'bathroom': {
                title: 'В ванной',
                listItems: [
                    'чистим раковину, настенную плитку и душ (ванну)',
                    'чистим смесители',
                    'моем зеркало и тумбочку под раковиной',
                    'обеспыливаем все поверхности',
                    'моем пол',
                ]
            },
        }
    },
    'maintenance-cleaning': {
        title: 'Что мы <span class="blue">убираем</span>',
        tabs: [
            {
                text: 'КОМНАТА',
                id: 'room',
            },
            {
                text: 'КУХНЯ',
                id: 'kitchen',
            },
            {
                text: 'КОРИДОР',
                id: 'corridor',
            },
            {
                text: 'ТУАЛЕТ',
                id: 'toilet',
            },
            {
                text: 'ВАННАЯ',
                id: 'bathroom',
            },
        ],
        tabsContent: {
            'room': {
                title: 'В комнате',
                listItems: [
                    'обеспыливаем доступные поверхности',
                    'выносим мусор',
                    'моем пол',
                ]
            },
            'kitchen': {
                title: 'В кухне',
                listItems: [
                    'моем кухонный фартук и плиту',
                    'моем доступные поверхности (столешницу, подоконник)',
                    'выносим мусор и меняем мусорный мешок',
                    'моем пол',
                    'протираем фасады шкафчиков и ручки',
                ]
            },
            'corridor': {
                title: 'В коридоре',
                listItems: [
                    'убираем пыль со всех поверхностей',
                    'моем зеркало зеркало',
                    'моем пол',
                ]
            },
            'toilet': {
                title: 'В туалете',
                listItems: [
                    'выносим мусор и меняем  мусорный мешок',
                    'моем раковину, смеситель и унитаз',
                    'обеспыливаем все поверхности',
                    'моем зеркало',
                    'моем пол',
                ]
            },
            'bathroom': {
                title: 'В ванной',
                listItems: [
                    'моем раковину и смесители',
                    'моем зеркало',
                    'обеспыливаем все поверхности',
                    'моем пол',
                ]
            },
        }
    },
};
export const whatWeCleanContentCN = {
    'cleaning-for-eviction': {
        title: '我们打扫的区域',
        tabs: [
            {
                text: '房间',
                id: 'room',
            },
            {
                text: '厨房',
                id: 'kitchen',
            },
            {
                text: '走廊',
                id: 'corridor',
            },
            {
                text: '厕所',
                id: 'toilet',
            },
            {
                text: '浴室',
                id: 'bathroom',
            },
        ],
        tabsContent: {
            'room': {
                title: '在房间里',
                listItems: [
                    '窗户双面清洗',
                    '擦拭清洁（窗台，架子，床头柜，桌子，椅子，床）',
                    '擦拭清洁柜橱上的灰尘',
                    '清洗门把手，开关和插座',
                    '清洁地板和墙壁',
                ]
            },
            'kitchen': {
                title: '在厨房里',
                listItems: [
                    '清洁橱柜和架子',
                    '解冻和清洁冰箱',
                    '清洁厨房围裙和灶具',
                    '清洗台面和其他表面（桌子，窗台，椅子）',
                    '窗户双面清洗',
                    '清洗垃圾箱和通风口',
                    '清洗地板（+清除重度脏污）',
                    '清洗门、把手、开关和插座',
                ]
            },
            'corridor': {
                title: '在走廊里',
                listItems: [
                    '擦洗所有灰尘',
                    '擦洗门和镜子',
                    '清洁地板',
                    '清洁冰箱（如果有的情况下）',
                ]
            },
            'toilet': {
                title: '在厕所里',
                listItems: [
                    '清洁垃圾桶',
                    '清洁马桶和水槽',
                    '清洁水龙头',
                    '擦拭清洁灰尘',
                    '清洁柜子和水槽下的柜子',
                    '清洁通风口',
                    '清洗地板',
                ]
            },
            'bathroom': {
                title: '在浴室里',
                listItems: [
                    '清洁水槽，墙面和淋浴（浴缸）',
                    '清洁水龙头',
                    '清洁镜子和梳妆台',
                    '擦拭清洁灰尘',
                    '清洗地板',
                ]
            },
        }
    },
    'maintenance-cleaning': {
        title: '我们什么 <span class="blue">消除</span>',
        tabs: [
            {
                text: '房间',
                id: 'room',
            },
            {
                text: '厨房',
                id: 'kitchen',
            },
            {
                text: '走廊',
                id: 'corridor',
            },
            {
                text: '厕所',
                id: 'toilet',
            },
            {
                text: '浴室',
                id: 'bathroom',
            },
        ],
        tabsContent: {
            'room': {
                title: '在房间',
                listItems: [
                    '擦拭清洁灰尘',
                    '清洁垃圾',
                    '清洁地板',
                ]
            },
            'kitchen': {
                title: '在厨房',
                listItems: [
                    '清洗围裙和炉灶',
                    '清洗擦拭灰尘（桌面、窗台）',
                    '倒垃圾和更换垃圾袋',
                    '清洁地板',
                    '擦洗橱柜及把手',
                ]
            },
            'corridor': {
                title: '在走廊',
                listItems: [
                    '清洗所有灰尘',
                    '清洗镜子',
                    '清洁地板',
                ]
            },
            'toilet': {
                title: '在厕所',
                listItems: [
                    '倒垃圾和更换垃圾袋',
                    '清洗水槽、水龙头和马桶',
                    '清洗擦拭所有灰尘',
                    '擦洗镜子',
                    '清洁地板',
                ]
            },
            'bathroom': {
                title: '在浴室',
                listItems: [
                    '清洗水槽和水龙头',
                    '清洗镜子',
                    '清洗擦拭所有灰尘',
                    '清洁地板',
                ]
            },
        }
    },
};


// FAQ
export const faqContentRU = {
    'cleaning-for-eviction': {
        title: 'Часто задаваемые <span class="blue">вопросы</span>',
        accordions: [
            {
                title: 'Я заключил договор на выселение, когда вы придете убирать?',
                content: 'Мы делаем уборку после выселения последнего студента из блока.',
                id: 1
            },
            {
                title: 'Если мы заключили договор, мы сможем выселиться в этот же день?',
                content: 'Да, вы предоставляете копию своего договора администрации общежития и выселяетесь, а мы согласовываем время уборки вашей комнаты с администрацией.',
                id: 2
            },
            {
                title: 'Я живу в общежитии, которого нет в списке, вы можете убрать у нас?',
                content: 'Мы работаем и в других университетах. Если вашего общежития нет в списке, необходимо обратиться к менеджеру <a href="https://t.me/cleanhubby" class="blue">CleanHub</a>.',
                id: 3
            },
            {
                title: 'Я выселяюсь сегодня, а остальные  через 2 недели, как нам быть?',
                content: 'Вы можете оплатить все вместе полную уборку блока сейчас, предоставить копию договора администрации и выезжать в любое время. Мы проводим уборку после выселения последнего студента из блока.',
                id: 4
            },
            {
                title: 'Можно ли заказать уборку только одной кухни или сан. узла?',
                content: 'Да, можно. Для оформления такого заказа необходимо связаться с нашим менеджером по телефону или мессенджерах.',
                id: 5
            },
            {
                title: ' Если я выселяюсь один, а другие останутся проживать еще несколько месяцев, что делать с уборкой?',
                content: 'Вы можете заказать Разовую уборку, которая подойдет для вашего выселения. Во время такой уборки мы убираем места общего пользования и ваше койко-место.',
                id: 6
            },
        ],
    },
    'maintenance-cleaning': {
        title: 'Часто задаваемые <span class="blue">вопросы</span>',
        accordions: [
            {
                title: 'Как происходит оплата услуг?',
                content: 'Вы заключаете договор и заранее оплачиваете нужное количество уборок.',
                id: 1
            },
            {
                title: 'Если я заказал уборку по подписке, то мне нужно платить за это целый год?',
                content: 'Нет, уборку по подписке можно отменить в любой момент',
                id: 2
            },
            {
                title: 'Договор подписывается с человеком или с блоком?',
                content: 'Договор подписывается с 1 человеком, проживающим в блоке. Срок действия договора- 1 месяц',
                id: 3
            },
            {
                title: 'Если человек, который заключал договор, выселится, что делать? ',
                content: 'Срок действия заключенного договора- 1 месяц. Мы выполним все условия договора, а в следующем месяце заключим с другим проживающим',
                id: 4
            },
            {
                title: 'Мне 17 лет, я могу заключить договор?',
                content: 'К сожалению, нет, договор можно заключить только с 18 лет.',
                id: 5
            },
            {
                title: 'Нужно ли всегда присутствовать во время уборки?',
                content: 'Нет, это не обязательно, после окончания уборки, клинер закроет дверь и оставит ключ на вахте.',
                id: 6
            },
            {
                title: 'Как будет происходить согласование времени уборки?',
                content: 'При оформлении договора вы указываете дни уборки. Наш менеджер предварительно связывается с вами для согласования времени в выбранные дни.',
                id: 7
            },
        ],
    },
    'wallpaper': {
        title: 'Часто задаваемые <span class="blue">вопросы</span>',
        accordions: [
            {
                title: 'Можно переклеить обои пока мы живем в комнате?',
                content: 'Да, можно. Это необходимо согласовать с менеджером СleanHub по телефону или в мессенджерах.',
                id: 1
            },
            {
                title: 'Чтобы вы переклеили обои мне нужно покупать свои обои?',
                content: 'Необязательно, при оформлении заказа вы указываете наличии или отсутствие у вас обоев.',
                id: 2
            },
            {
                title: 'Я заказал переклейку, мне нужно снять старые обои?',
                content: 'Нет, этим занимается наш специалист.',
                id: 3
            },
        ],
    },
    'mold-removal': {
        title: 'Часто задаваемые <span class="blue">вопросы</span>',
        accordions: [
            {
                title: 'Нам не нужно красить, только обработать, можно так заказать?',
                content: 'Да, можно, для оформления такого заказа необходимо прислать фотографию пораженной области нам в мессенджеры.',
                id: 1
            },
            {
                title: 'Я выселяюсь сегодня, мне срочно нужно обработать плесень?',
                content: 'Вы можете оплатить услугу сейчас, предоставить копию договора администрации и выезжать в любое время. Когда выселиться последний человек из блока, то по согласованию с администрацией общежития мы будем проводить работы.',
                id: 2
            },
        ],
    },
    'wall-repair': {
        title: 'Часто задаваемые <span class="blue">вопросы</span>',
        accordions: [
            {
                title: 'Я выселяюсь сегодня, мне срочно нужно починить стену?',
                content: 'Вы можете оплатить услугу сейчас, предоставить копию договора администрации и выезжать в любое время. После выселения последнего студента из блока мы согласуем время ремонта с администрацией общежития.',
                id: 1
            },
            {
                title: 'У нас сильное повреждение, вы сможете помочь?',
                content: 'Отправьте фотографию менеджеру в мессенджеры для подробной консультации.',
                id: 2
            },
            {
                title: 'Мы покрасим сами, мы сможете заштукатурить дырки?',
                content: 'Нет, мы делаем только комплексную работу.',
                id: 3
            },
        ],
    },
};
export const faqContentCN = {
    'cleaning-for-eviction': {
        title: '常见问题',
        accordions: [
            {
                title: '我签订了退宿合同后，你们什么时候来打扫？',
                content: '我们在最后一名学生搬出套间后进行清洁。',
                id: 1
            },
            {
                title: '如果我们签了合同，可以当天搬出吗？',
                content: '可以，您可以将合同复印件交给宿管，然后搬出，我们会与宿管安排时间打扫您的房间。',
                id: 2
            },
            {
                title: '我住的宿舍不在名单上，你们能帮我们打扫吗？',
                content: '我们也在其他大学工作。如果您的宿舍不在名单上，请联系 <a href="https://t.me/cleanhubby" class="blue">CleanHub</a> 经理。',
                id: 3
            },
            {
                title: '我今天要搬走，其他人两周后也要搬走，怎么办？',
                content: '你们可以现在一起支付整个房间的清洁费用，并给宿管提供合同复印件，然后随时搬出。我们会在最后一名学生搬出套间后进行清洁。',
                id: 4
            },
            {
                title: '是否可以要求只清洁一个厨房或浴室？',
                content: '可以。如果要下这样的订单，您必须通过电话或发信息联系我们的经理。',
                id: 5
            },
            {
                title: '如果我一个人搬出去，其他人要再住几个月，清洁工作怎么做？',
                content: '您可以订购适合您搬出的支持性清洁服务。在清洁期间，我们会打扫公共区域和您的指定位置。',
                id: 6
            },
        ],
    },
    'maintenance-cleaning': {
        title: '常见问题',
        accordions: [
            {
                title: '您如何支付服务费用？?',
                content: '您签订协议并提前支付所需清洁次数的费用。',
                id: 1
            },
            {
                title: '如果我通过订阅订购清洁服务，我是否需要支付一整年的费用？',
                content: '不，订阅清理可以随时取消',
                id: 2
            },
            {
                title: '协议是与人签署还是与区块签署？',
                content: '合同是与该街区的 1 名居住者签订的。合同期限 - 1 个月',
                id: 3
            },
            {
                title: '如果签订合同的人驱逐，我该怎么办？ ',
                content: '所签订协议的有效期为1个月。我们将履行合同的所有条款，下个月我们将与另一位居民签订合同',
                id: 4
            },
            {
                title: '我今年17岁了，可以签合同吗？',
                content: '不幸的是，不行，只有年满 18 岁才能签订合同。',
                id: 5
            },
            {
                title: '清洁期间我需要一直在场吗？',
                content: '不，这不是必须的，清洁工完成清洁后，会关上门并留下钥匙值班。',
                id: 6
            },
            {
                title: '如何协调清洁时间？',
                content: '签订合同时，您应注明清洁日期。我们的经理将提前与您联系以商定所选日期的时间.',
                id: 7
            },
        ],
    },
    'wallpaper': {
        title: '常见问题',
        accordions: [
            {
                title: '我们住在房间里时，可以贴壁纸吗？',
                content: '可以，您必须通过打电话或者发信息与СleanHub 的经理商定。',
                id: 1
            },
            {
                title: '贴壁纸需要自己购买壁纸吗?',
                content: '不一定，您可以在下单时说明是否您有壁纸。',
                id: 2
            },
            {
                title: '我需要提前清除旧壁纸吗？',
                content: '不需要，由我们专业工作人员清理。',
                id: 3
            },
        ],
    },
    'mold-removal': {
        title: '常见问题',
        accordions: [
            {
                title: '我们不需要喷漆，只需要清洁处理，可以这样下单吗？',
                content: '可以，您下这样的订单必须向我们发送一张发霉区域的照片信息。',
                id: 1
            },
            {
                title: '我今天搬走，是否需要当即处理霉菌？',
                content: '您可以现在支付服务费用，向管理员提供合同复印件，然后随时搬出。当宿舍的最后一个人从套间搬走后，我们将与宿管达成协议，开展工作。',
                id: 2
            },
        ],
    },
    'wall-repair': {
        title: '常见问题',
        accordions: [
            {
                title: '我今天要搬走，是否需要当即修缮墙壁',
                content: '您可以现在支付服务费用，向宿管提供合同复印件，然后随时搬出。当最后一名学生搬出宿舍后，我们将与宿管商定修缮时间。',
                id: 1
            },
            {
                title: '我们的墙面受损严重，您可以提供帮助吗？',
                content: '请向我们经理发送墙壁受损照片信息，以便详情咨询。',
                id: 2
            },
            {
                title: '.我们粉刷，您可以修补受损墙洞吗？',
                content: '不可以，我们必须做整体工作。',
                id: 3
            },
        ],
    },
};

// priceCalculator
export const priceCalculatorContentRU = {
    placeholder: 'Выберите общежитие',
    price: 'Цена',
    priceLabel: 'Выберите общежитие и узнайте цену',
    hostelsCount: {count: 9, title: 'общежитий БГУ'},
    workersCount: {count: 20, title: 'профессионалов'},
    clientsCount: {count: 1000, title: 'довольных клиентов'},
    'cleaning-for-eviction': {
        title: 'Сколько <span class="blue">стоит уборка?</span>',
        options: allRU.addressesWithOptions,
        prices: {
            '1.2': '405 руб.',
            '1.3': '440 руб.',
            '2': '290 руб.',
            '3': '290 руб.',
            '4': '405 руб.',
            '4.1': '405 руб.',
            '4.2': '405 руб.',
            '4.3': '405 руб.',
            '5': '120 руб.',
            '6.1': '120 руб.',
            '6.2': '100 руб.',
            '7': '290 руб.',
            '10': '405 руб.',
            '11': '405 руб.',
            '12': '405 руб.',
            '13': '405 руб.',
            '14': '405 руб.',
            '15': '405 руб.',
            '16': '290 руб.',
        }

    },
    'one-time-cleaning': {
        title: 'Сколько <span class="blue">стоит уборка?</span>',
        options: allRU.addresses,
        prices: {
            '1.2': '130 руб.',
            '1.3': '140 руб.',
            '2': '105 руб.',
            '3': '105 руб.',
            '4': '140 руб.',
            '4.1': '140 руб.',
            '4.2': '140 руб.',
            '4.3': '140 руб.',
            '5': '40 руб.',
            '6.1': '40 руб.',
            '6.2': '40 руб.',
            '7': '105 руб.',
            '10': '140 руб.',
            '11': '140 руб.',
            '12': '140 руб.',
            '13': '140 руб.',
            '14': '140 руб.',
            '15': '140 руб.',
            '16': '105 руб.',
        }
    },
    'maintenance-cleaning': {
        title: 'Сколько <span class="blue">стоит уборка?</span>',
        options: allRU.addresses,
        prices: {
            '1': '240 руб.',
            '2': '180 руб.',
            '3': '180 руб.',
            '4': '240 руб.',
            '4.1': '240 руб.',
            '4.2': '240 руб.',
            '4.3': '240 руб.',
            // '5': '40 руб.',
            // '6': '40 руб.',
            '7': '180 руб.',
            '10': '240 руб.',
            '11': '240 руб.',
            '12': '240 руб.',
            '13': '240 руб.',
            '14': '240 руб.',
            '15': '240 руб.',
            '16': '180 руб.',
        }
    },
    'wallpaper': {
        title: 'Сколько <span class="blue">стоит поклейка?</span>',
        options: allRU.addresses,
        prices: {
            '1': 'от 100 руб.',
            '2': 'от 100 руб.',
            '3': 'от 100 руб.',
            '4': 'от 100 руб.',
            '4.1': 'от 100 руб.',
            '4.2': 'от 100 руб.',
            '4.3': 'от 100 руб.',
            '5': 'от 100 руб.',
            '6': 'от 100 руб.',
            '7': 'от 100 руб.',
            '10': 'от 100 руб.',
            '11': 'от 100 руб.',
            '12': 'от 100 руб.',
            '13': 'от 100 руб.',
            '14': 'от 100 руб.',
            '15': 'от 100 руб.',
            '16': 'от 100 руб.',
        }
    },
    'mold-removal': {
        title: 'Сколько <span class="blue">стоит устранение плесени?</span>',
        options: allRU.addresses,
        prices: {
            '1': 'от 100 руб.',
            '2': 'от 100 руб.',
            '3': 'от 100 руб.',
            '4': 'от 100 руб.',
            '4.1': 'от 100 руб.',
            '4.2': 'от 100 руб.',
            '4.3': 'от 100 руб.',
            '5': 'от 100 руб.',
            '6': 'от 100 руб.',
            '7': 'от 100 руб.',
            '10': 'от 100 руб.',
            '11': 'от 100 руб.',
            '12': 'от 100 руб.',
            '13': 'от 100 руб.',
            '14': 'от 100 руб.',
            '15': 'от 100 руб.',
            '16': 'от 100 руб.',
        }
    },
    'wall-repair': {
        title: 'Сколько <span class="blue">стоит ремонт стены?</span>',
        options: [
            {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
            {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        ],
        prices: {
            '3': '170 руб.',
            '7': '170 руб.',
        }
    },
};

export const priceCalculatorContentCN = {
    placeholder: '选择宿舍',
    price: '价格',
    priceLabel: '选择宿舍并了解价格',
    hostelsCount: {count: 9, title: '白大宿舍'},
    workersCount: {count: 20, title: '专业人士'},
    clientsCount: {count: 1000, title: '满意的顾客'},
    'cleaning-for-eviction': {
        title: '清洁费用是多少',
        options: allCN.addressesWithOptions,
        prices: {
            '1.2': '405 卢布.',
            '1.3': '440 卢布.',
            '2': '290 卢布.',
            '3': '290 卢布.',
            '4': '405 卢布.',
            '4.1': '405 卢布.',
            '4.2': '405 卢布.',
            '4.3': '405 卢布.',
            '5': '120 卢布.',
            '6.1': '120 卢布.',
            '6.2': '100 卢布.',
            '7': '290 卢布.',
            '10': '405 卢布.',
            '11': '405 卢布.',
            '12': '405 卢布.',
            '13': '405 卢布.',
            '14': '405 卢布.',
            '15': '405 卢布.',
            '16': '290 卢布.',
        }

    },
    'one-time-cleaning': {
        title: '多少 <span class="blue">清洁费用?</span>',
        options: allCN.addresses,
        prices: {
            '1.2': '130 卢布.',
            '1.3': '140 卢布.',
            '2': '105 卢布.',
            '3': '105 卢布.',
            '4': '140 卢布.',
            '4.1': '140 卢布.',
            '4.2': '140 卢布.',
            '4.3': '140 卢布.',
            '5': '40 卢布.',
            '6.1': '40 卢布.',
            '6.2': '40 卢布.',
            '7': '105 卢布.',
            '10': '140 卢布.',
            '11': '140 卢布.',
            '12': '140 卢布.',
            '13': '140 卢布.',
            '14': '140 卢布.',
            '15': '140 卢布.',
            '16': '105 卢布.',
        }
    },
    'maintenance-cleaning': {
        title: '清洁费用是多少',
        options: allCN.addresses,
        prices: {
            '1': '240 卢布.',
            '2': '180 卢布.',
            '3': '180 卢布.',
            '4': '240 卢布.',
            '4.1': '240 卢布.',
            '4.2': '240 卢布.',
            '4.3': '240 卢布.',
            // '5': '40 卢布.',
            // '6': '40 卢布.',
            '7': '180 卢布.',
            '10': '240 卢布.',
            '11': '240 卢布.',
            '12': '240 卢布.',
            '13': '240 卢布.',
            '14': '240 卢布.',
            '15': '240 卢布.',
            '16': '180 卢布.',
        }
    },
    'wallpaper': {
        title: '贴壁纸多少钱？',
        options: allCN.addresses,
        prices: {
            '1': '从 100 卢布',
            '2': '从 100 卢布',
            '3': '从 100 卢布',
            '4': '从 100 卢布',
            '4.1': '从 100 卢布',
            '4.2': '从 100 卢布',
            '4.3': '从 100 卢布',
            '5': '从 90 卢布',
            '6': '从 90 卢布',
            '7': '从 100 卢布',
            '10': '从 90 卢布',
            '11': '从 90 卢布',
            '12': '从 100 卢布',
            '13': '从 100 卢布',
            '14': '从 100 卢布',
            '15': '从 100 卢布',
            '16': '从 100 卢布',
        }
    },
    'mold-removal': {
        title: '去除霉菌',
        options: allCN.addresses,
        prices: {
            '1': '从 100 卢布',
            '2': '从 100 卢布',
            '3': '从 100 卢布',
            '4': '从 100 卢布',
            '4.1': '从 100 卢布',
            '4.2': '从 100 卢布',
            '4.3': '从 100 卢布',
            '5': '从 100 卢布',
            '6': '从 100 卢布',
            '7': '从 100 卢布',
            '10': '从 100 卢布',
            '11': '从 100 卢布',
            '12': '从 100 卢布',
            '13': '从 100 卢布',
            '14': '从 100 卢布',
            '15': '从 100 卢布',
            '16': '从 100 卢布',
        }
    },
    'wall-repair': {
        title: '修补墙面需要多少钱？',
        options: [
            {value: '3', label: '№3 БГУ, ул. Ак. Курчатова, 6'},
            {value: '7', label: '№7 БГУ, ул. Октябрьская, 10/a'},
        ],
        prices: {
            '3': '170 卢布',
            '7': '170 卢布',
        }
    },
};

export const footerRU = {
    social_text: 'Присоединяйся к нашим социальным сетям! В них мы публикуем новости, а также <span class="orange">скидки и акции</span>',
    data: 'ООО "Эрфарунг" Свидетельство о государственной регистрации №193400527 от 17.03.2020 220045 г.Минск, ул. Чюрлениса, 7 пом. 202',
    job: 'Работа в',
    job_wage: 'от <span class="footer__job-wage">1000</span>',
    job_currency: 'руб. в месяц',
};

export const footerCN = {
    social_text: '加入我们的社交软件！我们在社交软件上发布新闻、折扣和促销信息',
    data: 'Erfarung 开放式有限公司 国家注册执照2020年3月17日 №193400527, 220045 Minsk, Churlenis 大街，202房间。',
    job: '招聘',
    job_wage: '从<span class="footer__job-wage">1000</span>',
    job_currency: '白俄卢布一个月',
};

export const thankYouRU = {
    thankYou: 'Спасибо!',
    text: 'Ваш заказ успешно оформлен. Мы свяжемся с вами в ближайшее время.',
    button: 'Вернуться на главную',
};

export const thankYouCN = {
    thankYou: '谢谢!',
    text: '您的订单已成功完成。我们会尽快与您联系。',
    button: '返回主页',
};

export const orderRU = {
    title: 'Оформление заказа',
    placeholder: 'Выберите общежитие',
    errorMessage: 'Пожалуйста, заполните обязательные поля.',
    addresses: allRU.addresses,
    services: {
        // '1' '№1 БГУ, ул. Свердлова, 34'
        '1': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения (2 комнатная)',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Уборка для выселения (3 комнатная)',
                price: 440,
                name: 'cleaning-for-eviction-2',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            // {
            //     title: 'Ремонт стен',
            //     price: 100,
            //     name: 'wall-repair',
            //     active: false,
            // },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '2' '№2 БГУ, ул. Октябрьская, 2'
        '2': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '3' '№3 БГУ, ул. Ак. Курчатова, 6'
        '3': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Ремонт стен',
                price: 170,
                name: 'wall-repair',
                active: false,
            },
        ],
        // '4', '№4 БГУ, ул. Чюрлениса, 7'
        '4': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.1', '№9 БГЭУ, ул. Чюрлениса, 1'
        '4.1': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.2', '№1 МГЛУ, ул. Чюрлениса, 3'
        '4.2': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.3', '№4 БГУ, ул. Чюрлениса, 5'
        '4.3': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '5', '№5 БГУ, ул. Октябрьская, 4'
        '5': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 40,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Уборка для выселения',
                price: 120,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '6', '№6 БГУ, ул. Октябрьская, 10'
        '6': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 40,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Уборка для выселения (с холодильником)',
                price: 120,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Уборка для выселения (без холодильника)',
                price: 100,
                name: 'cleaning-for-eviction-2',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '7', '№7 БГУ, ул. Октябрьская, 10/a'
        '7': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Ремонт стен',
                price: 170,
                name: 'wall-repair',
                active: false,
            },
        ],
        // '10', '№10 БГУ, ул. Курчатова, 8'
        '10': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '11', '№11 БГУ, ул. пр-т Дзержинского, 87'
        '11': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '12', '№18 БНТУ, пр-т Дзержинского, 83/16'
        '12': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '13', '№10 БГМУ, пр-т Дзержинского, 93'
        '13': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '14', '№4 БГУИР, пр-т Дзержинского, 95'
        '14': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '15', '№4 МГЛУ, пр-т Дзержинского, 97'
        '15': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Поклейка обоев',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: 'Уборка мест общего пользования для выселения',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '16', '№ 5 БГПУ, пер.Вузовский, 9/1'
        '16': [
            {
                title: 'Разовая уборка (всего блока)',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: 'Поддерживающая уборка по подписке (2 уборки в месяц)',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: 'Поддерживающая уборка по подписке (4 уборки в месяц)',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: 'Уборка для выселения',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: 'Устранение плесени',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
    },
    processingPersonalData: 'Даю согласие на обработку моих персональных данных в маркетинговых целях.',
    step: 'ШАГ',
    step1: 'Выберите общежитие и комнату',
    step2: 'Выберите услуги',
    step3: 'Введите личные данные',
    asideButtonText: 'Заказать на сумму',
    buttonText: 'Оформить заказ на сумму',
    room: 'Введите номер комнаты',
    name: 'Имя*',
    surName: 'Фамилия*',
    phone: 'Номер телефона*',
    email: 'Email*',
    socialConnect: 'Связаться со мной в Telegram, Viber или Wechat',
    textareaPlaceholder: 'Если есть, что добавить...',
    roomValidationMessage: 'Введите номер комнаты',
    nameValidationMessage: 'Введите как минимум 1 символ.',
    surNameValidationMessage: 'Введите как минимум 1 символ.',
    phoneValidationMessage: 'Неверный номер телефона.',
    emailValidationMessage: 'Неверный Email.',
};

export const orderCN = {
    title: '正在下单',
    placeholder: '选择旅舍',
    errorMessage: '请填写必填字段',
    addresses: allCN.addresses,
    services: {
        // '1' '№1 БГУ, ул. Свердлова, 34'
        '1': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐清洁（2个房间）',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '驱逐清洁（3个房间）',
                price: 440,
                name: 'cleaning-for-eviction-2',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '2' '№2 БГУ, ул. Октябрьская, 2'
        '2': [
            {
                title: '一次性清洁（整机）',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '3' '№3 БГУ, ул. Ак. Курчатова, 6'
        '3': [
            {
                title: '一次性清洁（整机）',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '墙面修复',
                price: 170,
                name: 'wall-repair',
                active: false,
            },
        ],
        // '4', '№4 БГУ, ул. Чюрлениса, 7'
        '4': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.1', '№9 БГЭУ, ул. Чюрлениса, 1'
        '4.1': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.2', '№1 МГЛУ, ул. Чюрлениса, 3'
        '4.2': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '4.3', '№4 БГУ, ул. Чюрлениса, 5'
        '4.3': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '5', '№5 БГУ, ул. Октябрьская, 4'
        '5': [
            {
                title: '一次性清洁（整机）',
                price: 40,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '驱逐前清洁',
                price: 120,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '6', '№6 БГУ, ул. Октябрьская, 10'
        '6': [
            {
                title: '一次性清洁（整机）',
                price: 40,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '驱逐前清洁 (с холодильником)',
                price: 120,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '驱逐前清洁 (без холодильника)',
                price: 100,
                name: 'cleaning-for-eviction-2',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
        // '7', '№7 БГУ, ул. Октябрьская, 10/a'
        '7': [
            {
                title: '一次性清洁（整机）',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '墙面修复',
                price: 170,
                name: 'wall-repair',
                active: false,
            },
        ],
        // '10', '№10 БГУ, ул. Курчатова, 8'
        '10': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '11', '№11 БГУ, ул. пр-т Дзержинского, 87'
        '11': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '12', '№18 БНТУ, пр-т Дзержинского, 83/16'
        '12': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '13', '№10 БГМУ, пр-т Дзержинского, 93'
        '13': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '14', '№4 БГУИР, пр-т Дзержинского, 95'
        '14': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '15', '№4 МГЛУ, пр-т Дзержинского, 97'
        '15': [
            {
                title: '一次性清洁（整机）',
                price: 140,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 240,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 400,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 405,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '贴壁纸',
                price: 100,
                name: 'wallpaper',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
            {
                title: '清洁驱逐公共区域',
                price: 340,
                name: 'cleaning-for-eviction-general',
                active: false,
            },
        ],
        // '16', '№ 5 БГПУ, пер.Вузовский, 9/1'
        '16': [
            {
                title: '一次性清洁（整机）',
                price: 105,
                name: 'one-time-cleaning',
                active: true,
            },
            {
                title: '订阅维护清洁（每月 2 次清洁）',
                price: 180,
                name: 'maintenance-cleaning__2',
                active: false,
            },
            {
                title: '订阅维护清洁（每月 4 次清洁）',
                price: 340,
                name: 'maintenance-cleaning__4',
                active: false,
            },
            {
                title: '驱逐前清洁',
                price: 290,
                name: 'cleaning-for-eviction',
                active: false,
            },
            {
                title: '脱模',
                price: 100,
                name: 'mold-removal',
                active: false,
            },
        ],
    },
    processingPersonalData: '我同意出于营销目的处理我的个人数据。',
    step: '步',
    step1: '选择宿舍和房间',
    step2: '选择服务',
    step3: '输入个人信息',
    asideButtonText: '订购金额',
    buttonText: '下订单金额',
    room: '输入房间号',
    name: '姓名*',
    surName: '姓*',
    phone: '电话号码*',
    email: '电子邮件*',
    socialConnect: '联系我： Telegram, Viber 或者 wechat',
    textareaPlaceholder: '如果您有什么要补充的...',
    roomValidationMessage: '输入房间号',
    nameValidationMessage: '输入至少2个字符.',
    surNameValidationMessage: '输入至少2个字符.',
    phoneValidationMessage: '电话号码错误.',
    emailValidationMessage: '不合规电邮.',
};


// 'cleaning-for-eviction'
// 'maintenance-cleaning'
// 'wallpaper'
// 'mold-removal'
// 'wall-repair'

