import React from 'react';
import Header from '../components/header/Header';
import {Footer} from '../components/footer/Footer';

const PublicOffer = () => {
    return (
        <>
            <Header/>
            <div className="wrapper with-padding">
                <h2>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ ПО ОКАЗАНИЮ УСЛУГ</h2>
                <p className="text">
                    <br/>
                    г. Минск
                    <br/>
                    Общество с ограниченной ответственностью "Эрфарунг", именуемый в дальнейшем «Исполнитель», в лице
                    директора Ковалёвой Дианы Сергеевны, действующей на основании устава, именуемый в дальнейшем
                    «Исполнитель», настоящей публичной офертой предлагает заключить Договор на оказание клининговых
                    услуг с любым физическим лицом, предоставившим свое согласие (Акцепт) на покупку предоставляемым
                    Исполнителем услуг (далее – «Договор»). Настоящий Договор имеет статус официальной письменной
                    публичной оферты, составленной в соответствии со статьей 407 Гражданского кодекса Республики
                    Беларусь (далее – ГК РБ). Договор считается заключенным и приобретает силу с момента совершения
                    Заказчиком всех действий, предусмотренных Офертой и означающих безоговорочное принятие всех условий
                    Оферты без каких-либо изъятий или ограничений на условиях присоединения.
                </p>

                <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
                <p className="text">
                    Оферта – настоящий документ, опубликованный на сайте Cleanhub.by<br/>
                    Акцепт Оферты – полное и безоговорочное принятие Оферты путем проведения оплаты в порядке,
                    определяемом настоящим Договором.<br/>
                    Заказчик – совершеннолетнее лицо, осуществившее Акцепт Оферты, являющееся потребителем услуг по
                    настоящему Договору, либо правомочный представитель несовершеннолетнего лица, которое будет
                    потребителем услуг.<br/>
                    Сайт – интернет ресурс, содержащий полную информацию о содержании и стоимости услуг, размещенный по
                    адресу Cleanhub.by<br/>
                    Клининговые услуги – услуги по уборке комнат в общежитии.<br/>
                    Косметический ремонт – услуги по поклейке обоев, ремонту стен, потолка в общежитии.
                </p>

                <h2>2. ПРЕДМЕТ И ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                <p className="text">
                    Исполнитель обязуется оказывать Заказчику Услуги, перечисленные в п. 1.5. Договора, а Заказчик
                    обязуется принимать и оплачивать эти Услуги в соответствии с условиями, предусмотренными настоящим Договором. <br/>
                    Подробная информация об объеме оказываемых Услуг по настоящему договору содержится на сайте
                    Исполнителя Cleanhub.by и является неотъемлемой частью настоящего договора. <br/>
                    Исполнитель не оказывает Услуги в жилых помещениях, находящихся в аварийном, послеаварийном,
                    антисанитарном, непригодном для проживания состоянии, в жилых помещениях, содержащих следы
                    последствий противоправных деяний (правонарушений, преступлений).<br/>
                    Для исполнения своих обязательств по настоящему договору Исполнитель вправе привлекать третьих лиц,
                    а также использовать услуги и работы третьих лиц.<br/>
                    Оплатой Заказчик выражает свое полное и безоговорочное принятие условий настоящего договора (Акцепт
                    Оферты). Осуществляя Акцепт Оферты Заказчик гарантирует, что ознакомлен и соглашается, полностью и
                    безоговорочно принимает все условия настоящего Договора в том виде, в котором они изложены в настоящем Договоре. <br/>
                    Территория действия настоящего договора – город Минск и Минский район, Республика Беларусь. Стороны
                    вправе по взаимному согласию изменять территорию действия настоящего договора. Соглашение считается
                    достигнутым в случае подтверждения Исполнителем принятия Заявки Заказчика, содержащей точные
                    сведения о месте оказания услуг, находящемся за пределами территории, указанной в ч. 1 настоящего пункта.
                </p>

                <h2>3. ПОРЯДОК ОКАЗАНИЯ УСЛУГ</h2>
                <p className="text">
                    Исполнитель оказывает Услуги на основании Заявки Заказчика, оформленной через сайт Исполнителя
                    Cleanhub.by или направленной Исполнителю любым другим способом ( <br/>
                    телефон: +375 29 207 7771; <br/>
                    инстаграм: https://www.instagram.com/cleanhub.by;<br/>
                    telegram: +375 29 207 7771;<br/>
                    https://t.me/cleanhubby;<br/>
                    whatsapp: +375 29 207 7771; <br/>
                    viber: +375 29 207 7771.<br/>
                    ), содержащей сведения, которые, согласно п. 3.2.
                    настоящего договора являются обязательными для подтверждения Заявки. <br/>
                    Форма Заявки состоит из позиций, подлежащих обязательному заполнению, в связи с чем, Заказчик в
                    обязательном порядке указывает свои контактные данные (имя, контактный телефон, фактический адрес
                    оказания услуг), а также данные, имеющие отношение к оказанию Услуг (возможные дата и временной
                    промежуток оказания Услуг, количество комнат, набор дополнительных услуг (при необходимости) и т.д.
                    Надлежащее заполнение Заявки является акцептом публичной оферты в порядке п. 1 ст. 408 Гражданского
                    кодекса Республики Беларусь. <br/>
                    Заказчик вправе аннулировать либо изменить Заявку в порядке и на условиях, предусмотренных настоящим
                    договором. <br/>
                    Исполнитель оказывает услуги с использованием собственного уборочного инвентаря и чистящих средств
                    по
                    месту оказания Услуг, указанному Заказчиком в Заявке.<br/>
                    Если в процессе оказания Услуг будет выявлена невозможность устранения тех или иных загрязнений,
                    Исполнитель обязан провести контрольную чистку в присутствии Заказчика с занесением в акт об
                    оказании
                    услуги (далее – Акт) подтверждения Заказчика о невозможности устранения подобных загрязнений, что
                    лишает
                    Заказчика права предъявлять претензии по качеству оказанной услуги по данному факту. <br/>
                    В случае явных недостатков оказанной Услуги Заказчик обязан уведомить об этом лицо, непосредственно
                    оказывающее Услуги, немедленно после окончания оказания Услуги. В таком случае лицо, непосредственно
                    оказывающее Услуги, незамедлительно принимает все возможные действия для устранения таких
                    недостатков. <br/>
                    Документом, подтверждающим оказание Услуги Заказчику и выполнение Заявки качественно, в срок и в
                    полном
                    объеме, является акт оказания услуги (Акт), согласно Постановления Министерства финансов Республики
                    Беларусь от 12.02.2018г. №13 «О единоличном составлении первичных учетных документов», Стороны
                    подтверждают и соглашаются с тем, что Акт об оказании услуг, в соответствии с настоящим Договором,
                    составляется Исполнителем единолично.
                </p>

                <h2>ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
                <h2>ЗАКАЗЧИК ИМЕЕТ ПРАВО:</h2>
                <p className="text">
                    контролировать процесс оказания Услуг;<br/>
                    аннулировать либо изменить Заявку путем направления письменного обращения Исполнителю либо по
                    контактному номеру: +375 29 207 7771 не позднее, чем за 5 (пять) рабочих дней до начала оказания
                    Услуги; <br/>
                    присутствовать при оказании Услуг либо обеспечить присутствие третьих лиц, обладающих полной
                    дееспособностью, проживающих в помещении, на которых возлагается ответственность за сохранность
                    имущества, находящегося в помещении; <br/>
                    требовать устранения недостатков, возникших по вине Исполнителя.
                </p>

                <h2>ЗАКАЗЧИК ОБЯЗАН:</h2>
                <p className="text">
                    принять и оплатить оказанные услуги в срок и на условиях, предусмотренных настоящим договором;<br/>
                    указать в Заявке достоверные и полные сведения, необходимые для надлежащего оказания Услуг по
                    настоящему
                    договору; <br/>
                    обеспечить лицам, непосредственно осуществляющим оказание Услуг, доступ в помещения для оказания
                    Услуг; <br/>
                    обеспечить сохранность денежных средств и ценных вещей;<br/>
                    обеспечить Исполнителя источниками электропитания и холодного/горячего водоснабжения;<br/>
                    уведомить Исполнителя о необходимости переноса срока оказания Услуг не позднее, чем за 1 (один)
                    рабочий
                    день до предполагаемой даты оказания Услуг.
                </p>

                <h2>ИСПОЛНИТЕЛЬ ИМЕЕТ ПРАВО:</h2>
                <p className="text">
                    требовать оплаты оказанных Услуг по настоящему договору;<br/>
                    отказаться от исполнения настоящего договора полностью или частично в случае, если Заказчиком не
                    созданы
                    или ненадлежащим образом созданы условия, предусмотренные настоящим договором: в случае
                    предоставления
                    Заказчиком неполной либо недостоверной информации об объеме услуг, указанных в Заявке, в случае если
                    оказание Услуг может представлять угрозу для жизни и здоровья лиц, непосредственно направленных
                    Исполнителем для оказания Услуг, в случае если Заказчиком не обеспечен доступ к помещению в
                    согласованные дату и сроки, в случае если Заказчиком не исполнены условия настоящего договора по
                    обеспечению лиц, непосредственно направленных Исполнителем для оказания Услуг, источниками
                    электропитания, горячего/холодного водоснабжения, в случае если жилое помещение находится аварийном,
                    послеаварийном, антисанитарном, непригодном для проживания состоянии, а также в домах, признанных
                    аварийными или подлежащими сносу, в случае если в жилом помещении содержатся следы последствий
                    совершения противоправных деяний (правонарушения, преступления);<br/>
                    в одностороннем порядке изменять и определять стоимость оказываемых Услуг; <br/>
                    отменить Заявку в любое время до наступления времени оказания Услуг с обязательным уведомлением о
                    данном
                    действии Заказчика посредством голосового сообщения или sms, оставленными Заказчиком в качестве
                    контактных данных;<br/>
                    самостоятельно определять способы оказания Услуг и количество лиц, непосредственно оказывающих
                    Услуги.
                </p>

                <h2>ИСПОЛНИТЕЛЬ ОБЯЗАН:</h2>
                <p className="text">
                    оказать Услуги Заказчику в соответствии с условиями Заявки, оформленной и направленной Заказчиком в
                    соответствии с настоящим договором;<br/>
                    оказать Услуги Заказчику качественно и своевременно;<br/>
                    использовать все личные данные Заказчика, указанные в Заявке, исключительно для оказания Услуг в
                    рамках
                    настоящего договора;<br/>
                    исправить по требованию Заказчика все выявленные недостатки, если в процессе оказания услуг
                    Исполнитель
                    допустил отступление от условий договора, ухудшившее качество работы, в течение 3 дней;<br/>
                    Стороны обладают также иными правами и обязанностями, предусмотренными настоящим договором и
                    законодательством Республики Беларусь.
                </p>

                <h2>5. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h2>
                <p className="text">
                    Стоимость услуг определяется на основании действующего прейскуранта, опубликованного на сайте
                    Cleanhub.by. По соглашению между Исполнителем и Заказчиком цены, могут отличаться от прейскуранта.
                    Услуги не облагаются НДС в соответствии с действующим Налоговым Кодексом Республики Беларусь, в
                    связи с
                    тем, что Исполнитель применяет УСН без НДС.<br/>
                    Заказчик оплачивает Услуги в следующем порядке: 100% (сто процентов) от общей стоимости Услуг по
                    предоплате путем внесения на счет Исполнителя.<br/>
                    В случае изменения цен на Услуги Исполнителем, при оформлении Заявки и еѐ подтверждения Заказчиком,
                    Услуги подлежат оплате по стоимости, определенной на момент оформления и подтверждения Заявки.
                </p>

                <h2>6. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p className="text">
                    Стороны несут ответственность за неисполнение либо ненадлежащее исполнение своих обязательств по
                    настоящему договору в соответствии с законодательством Республики Беларусь.<br/>
                    Исполнитель не несет ответственность за сохранность предоставленного для оказания Услуг помещения и
                    имущества, находящегося в нем, кроме случаев доказанной в установленном законодательством Республики
                    Беларусь порядке вины Исполнителя".<br/>
                    Ответственность за недостоверное предоставление сведений (их точность и полноту) при оформлении
                    Заявки
                    возлагается на Заказчика. Заказчик не вправе предъявлять претензии к Исполнителю о неисполнении либо
                    ненадлежащем исполнении обязательств в рамках настоящего договора при указании в Заказе
                    недостоверных
                    сведений, имеющих значение для надлежащего исполнения Исполнителем своих обязательств.<br/>
                    В случае ненадлежащего оказания услуг Исполнителем либо их неоказания Исполнитель несет
                    ответственность
                    в соответствии с Законом Республики Беларусь от 09.01.2002 № 90-З «О защите прав потребителей».
                    Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по
                    настоящему
                    договору, если неисполнением явилось следствие обстоятельств непреодолимой силы, возникших после
                    заключения настоящего договора, которые Сторона не могла предвидеть. К обстоятельствам непреодолимой
                    силы относятся события, на которые сторона не может оказать влияние и за возникновение которых не
                    несет
                    ответственности (землетрясение, наводнение, пожары, другие стихийные бедствия, отсутствие
                    электроэнергии/ или сбои в работе компьютерной сети, забастовки, беспорядки, и др.).
                </p>

                <h2>ПОРЯДОК УРЕГУЛИРОВАНИЯ СПОРОВ</h2>
                <p className="text">
                    В случае оказания услуги ненадлежащего качества, ненадлежащим образом, либо ее неоказания,
                    неустранения
                    явных недостатков, обнаруженных в процессе приемки оказанных услуг, Заказчик вправе в течение 1
                    (одного)
                    рабочего дня направить Исполнителю претензию посредством почтовой корреспонденции с подробным
                    описанием
                    возникших претензий по факту оказания Услуги.<br/>
                    Заказчик обязан рассмотреть претензию в срок не позднее 5 (пяти) рабочих дней с момента его
                    получения и
                    проинформировать о результатах рассмотрения Исполнителя.<br/>
                    Заказчик обязан представить подтверждение (в том числе фото/видео материалы) факта некачественно
                    оказанной услуги.<br/>
                    В случае неурегулирования спора во внесудебном/досудебном порядке споры между Сторонами подлежат
                    разрешению в судебном порядке.
                </p>

                <h2>ИНЫЕ ПОЛОЖЕНИЯ</h2>
                <p className="text">
                    Настоящий договор считается заключенным и вступает в силу с момента подтверждения Заказчиком своего
                    согласия с его условиями путем нажатия кнопки «Отправить заявку»/«Заказать звонок» на этапе
                    завершения
                    оформления Заявки, и действует до полного исполнения Сторонами своих обязательств. Нажатие кнопки
                    «Отправить заявку»/«Заказать звонок» служит так же подтверждением, что Заказчик ознакомлен с
                    договором
                    публичной оферты на оказание клининговых услуг, условия публичной оферты принимает, даёт согласие на
                    обработку его персональных данных.
                    Заказчику гарантируется, что указанные им персональные данные используются Исполнителем
                    исключительно в
                    целях надлежащего исполнения условий настоящего договора.<br/>
                    Заказчик, совершая акцепт настоящей оферты, подтверждает свое согласие на разглашение своих
                    персональных
                    данных исключительно Исполнителю и лицам, непосредственно направленным Исполнителем для оказания
                    Услуг в
                    рамках настоящего договора.
                </p>

                <h2>9. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ:</h2>

                <p className="text">
                    Общество с ограниченной ответственностью «Эрфарунг»<br/>
                    Адрес: г. Минск, ул. Чюрлениса, д.7, пом. 202<br/>
                    УНП 193400527<br/>
                    Р/c BY59BLNB30120000304286000933 в БНБ-банк<br/>
                    БИК SWIFT: BLNBBY2<br/>
                    адрес банка г.Минск, пр.Независимости, 87А<br/>
                    Контактный телефон: +375 29 207 7771.<br/>
                </p>
            </div>
            <Footer/>
        </>
    );
};

export default PublicOffer;
