import React from 'react';
import Header from '../components/header/Header';
import {Footer} from '../components/footer/Footer';

const PrivacyPolicy = () => {
    return (
        <>
            <Header/>
            <div className="wrapper with-padding">
                <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
                <p className="text">
                    Дата публикации: 2023г. <br/>

                    Настоящая Политика конфиденциальности распространяется на сайт Cleanhub.by и его поддомены (далее –
                    сайт). <br/>
                    Используя сайт и (или) оставляя свои персональные данные на сайте, Пользователь выражает свое
                    согласие на использование данных на условиях, изложенных в настоящей Политике конфиденциальности.
                    Отношения, связанные со сбором, хранением, распространением и защитой информации, предоставляемой
                    Пользователем, регулируются настоящей Политикой и действующим законодательством Республики Беларусь.
                    В случае несогласия Пользователя с условиями настоящей Политики использование сайта должно быть
                    немедленно прекращено.
                </p>


                <h2>1. ТЕРМИНЫ</h2>
                <p className="text">
                    1.1. Сайт - сайт, расположенный в сети Интернет по адресу https://www.cleanhub.by/.<br/>
                    Все исключительные права на сайт и его отдельные элементы (включая программное обеспечение, дизайн)
                    принадлежат в полном объеме владельцу сайта – ООО «Эрфарунг» (зарегистрировано в Республике Беларусь
                    и расположено по адресу: г. Минск, ул. Чюрлениса, д.7, пом. 202).<br/>
                    1.2. Администрация сайта – официальные представители владельца сайта, имеющие права на управление
                    сайтом и (или) обработку персональных данных.<br/>
                    1.3. Пользователь – лицо, имеющее доступ к сайту через сеть Интернет и использующее информацию,
                    представленную на сайте, в личных либо служебных целях.<br/>
                    1.4. Персональные данные — персональные данные Пользователя, которые Пользователь предоставляет о
                    себе самостоятельно или в процессе использования функционала сайта.<br/>
                    1.5. Обработка персональных данных – любые действия, совершаемые с персональными данными, включая
                    сбор, запись, систематизацию, накопление, хранение, обновление, использование, передачу, удаление
                    персональных данных.<br/>
                    1.6. Конфиденциальность персональных данных – обязательное требование не допускать их
                    распространения, за исключением случаев, установленных законодательством.
                </p>

                <h2>2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ. ПРАВА ПОЛЬЗОВАТЕЛЕЙ</h2>
                <p className="text">
                    2.1. ООО «Эрфарунг» собирает и хранит только те персональные данные, которые необходимы для
                    идентификации Пользователя, оказания услуг и взаимодействия с Пользователем.<br/>
                    В случаях, когда Пользователь заполняет заявку на оказание услуг, регистрирует учетную запись на
                    сайте, связывается со службой технической поддержки ООО «Эрфарунг», подписывается на рассылки ООО
                    «Эрфарунг», участвует в любых соответствующих акциях, конкурсах, розыгрышах, совершает любые иные
                    действия на сайте и/или использует любую часть сайта, он дает согласие:<br/>
                    - на обработку своих персональных данных, включая сбор, систематизацию, накопление, хранение,
                    обработку, уточнение (обновление, изменение), сопоставление, использование, обезличивание,
                    блокирование, уничтожение, а также осуществление любых иных действий с персональными данными с
                    учетом действующего законодательства;<br/>
                    - на получение рекламных материалов, новостной рассылки и (или) иной информации от ООО «Эрфарунг»
                    посредством электронной, факсимильной связи, SMS, Viber, WhatsApp и иных средств связи;<br/>
                    - проведение статистических и иных исследований.<br/>
                    Пользователь дает согласие на использование персональных данных также в целях:
                    - идентификации Пользователя, зарегистрированного на сайте;<br/>
                    - предоставления и предложения товаров, работ (услуг);<br/>
                    - улучшения качества предоставления услуг и качества сайта, удобства его использования, разработки
                    новых функций и возможностей;<br/>
                    - предоставления сервисов сайта;<br/>
                    - установления с Пользователем обратной связи, включая направление уведомлений, касающихся
                    использования сайта, оказания услуг, обработка сообщений от Пользователя.<br/>
                    - подтверждения достоверности и полноты персональных данных, предоставленных Пользователем;<br/>
                    - создания учетной записи Пользователя;<br/>
                    - предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
                    проблем, связанных с использованием сайта.<br/>
                    Личные сведения, собранные ООО «Эрфарунг» посредством сайта, могут быть объединены с информацией,
                    которую Пользователь сообщает другими способами (устно, письменно, посредством факсимильной связи и
                    т.п.).<br/>
                    Согласие Пользователя действует до момента его отзыва Пользователем путем направления
                    соответствующего письменного уведомления ООО «Эрфарунг» по адресу электронной почты: <br/>
                    bsuclean@gmail.com. <br/>
                    2.2. ООО «Эрфарунг» собирает и обрабатывает следующие персональные данные:<br/>
                    - фамилия, имя и отчество;<br/>
                    - адрес электронной почты;<br/>
                    - контактные номера телефонов;<br/>
                    - адрес предоставления услуги;<br/>
                    - реквизиты компании;<br/>
                    - число, месяц, год рождения;<br/>
                    - занимаемая должность;<br/>
                    - любая иная информация, связанная с личностью Пользователя.<br/>
                    2.3. Пользователь имеет право:<br/>
                    2.3.1. узнать (запросить) какие его персональные данные хранятся у ООО «Эрфарунг»;<br/>
                    2.3.2. требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если
                    персональные данные являются неполными, устаревшими, недостоверными;<br/>
                    2.3.3. отозвать свое согласие на сбор и обработку персональных данных.<br/>
                    2.3.4. получать информацию о сроках обработки своих персональных данных, в том числе о сроках их
                    хранения;<br/>
                    2.3.5. обжаловать в уполномоченном органе по защите прав субъектов персональных данных или в
                    судебном порядке неправомерные действия или бездействия при обработке его персональных данных;<br/>
                    2.4. на уничтожение персональных данных в случае отзыва своего согласия в порядке, предусмотренном
                    п.3.6. Политики конфиденциальности.<br/>
                    2.5. ООО «Эрфарунг» не несет ответственности за недостоверную информацию, предоставленную
                    Пользователем.<br/>
                    2.6. Пользователь соглашается с тем, что если он разрешил предоставление его персональной информации
                    третьим лицам, то ООО «Эрфарунг» не может контролировать использование третьими лицами раскрытых
                    персональных данных Пользователя.<br/>
                </p>

                <h2>3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p className="text">
                    3.1. ООО «Эрфарунг» обязуется использовать персональные данные в соответствии с действующим
                    законодательством Республики Беларусь.<br/>
                    3.2. В отношении персональных данных и иных данных Пользователя сохраняется их конфиденциальность,
                    кроме случаев:<br/>
                    когда указанные данные являются общедоступными;<br/>
                    добровольного предоставления Пользователем информации о себе для общего доступа неограниченному
                    кругу лиц;<br/>
                    использования данных с согласия Пользователя;<br/>
                    необходимости использования Пользователем определенного сервиса сайта либо для оказания услуги
                    Пользователю;<br/>
                    необходимости предоставления такой информации в соответствии с законодательством Республики Беларусь
                    или иным применимым законодательством и (или) на основании требования уполномоченного
                    государственного органа.<br/>
                    3.3. Персональная информация хранится на серверах ООО «Эрфарунг».<br/>
                    3.4. ООО «Эрфарунг» осуществляет надлежащую защиту персональных и иных данных в соответствии с
                    законодательством Республики Беларусь и принимает необходимые и достаточные организационные и
                    технические меры для защиты персональных данных Пользователя.<br/>
                    3.5. В случае возникновения у Пользователя каких-либо вопросов в отношении настоящей Политики
                    конфиденциальности и (или) об осуществлении своих прав, а также для отзыва своего согласия,
                    Пользователь связывается с ответственным представителем ООО «Эрфарунг» по адресу электронной почты:
                    bsuclean@gmail.com.<br/>
                    3.6. В случае получения отзыва согласия Пользователя на обработку персональных данных, ООО
                    «Эрфарунг» прекращает работу с персональными данными.
                </p>

                <h2>4. ССЫЛКИ НА ДРУГИЕ САЙТЫ</h2>
                <p className="text">
                    4.1. На сайте есть и будут в дальнейшем появляться ссылки на внешние сайты. ООО «Эрфарунг» не
                    контролирует их содержимое и не отвечает за них. Эти сайты должны иметь собственные политики
                    конфиденциальности.
                </p>

                <h2>5. ОБНОВЛЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h2>
                <p className="text">
                    5.4. ООО «Эрфарунг» имеет право в любой момент изменять Политику конфиденциальности (полностью или в
                    части) в одностороннем порядке без предварительного согласования с Пользователем.<br/>
                    5.5. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено
                    новой редакцией Политики. Действующая редакция всегда находится на странице по адресу
                    https://www.cleanhub.by/<br/>
                    5.6. Пользователь обязуется регулярно самостоятельно следить за изменениями Политики
                    конфиденциальности путем ознакомления с актуальной редакцией.
                </p>

                <h2>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
                <p className="text">
                    6.1. К настоящей Политике и отношениям между Пользователем и ООО «Эрфарунг», возникающим в связи с
                    применением Политики конфиденциальности, подлежит применению право Республики Беларусь.<br/>
                    6.2. Переписка между ООО «Эрфарунг» и Пользователем осуществляется по электронной почте. ООО
                    «Эрфарунг» направляет письма Пользователю по адресу, указанному Пользователем. Отправленное ООО
                    «Эрфарунг» письмо по электронной почте считается полученным Пользователем в тот же день.
                </p>
            </div>
            <Footer/>
        </>
    );
};

export default PrivacyPolicy;
